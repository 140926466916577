













import { Component, Vue, Prop } from 'vue-property-decorator';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import { getDemandRange } from '@/modules/common/filters/demand-filters';

@Component({
    components: {
        Demand,
    },
})

export default class RatesPopupDemand extends Vue {
    @Prop({
        type: Number,
    })
    demand!: number | null;

    public demandLevel!: string;

    get demandValue() {
        if (this.demand) {
            return Number(this.demand.toFixed());
        }
        return null;
    }

    get demandCaption(): string {
        const range = getDemandRange(this.demandValue || 0);

        if (range) {
            return range.title;
        }

        return '';
    }
}
