











import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import Day from '@/modules/common/types/day.type';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';
import formatDate from '@/modules/common/filters/format-date.filter';

@Component({
    components: {
        ScanBtnSmall,
    },
})
export default class RatesScanBtn extends Vue {
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    @Prop({
        type: Number,
        default: () => null,
    })
    private day!: Day | null;

    @Prop({
        type: Object,
        default: () => null,
    })
    private data!: RatesDocumentModel | null;

    @Prop({
        type: Object,
        default: () => null,
    })
    private settings!: RatesSettingsModel | null;

    @Prop({
        type: Boolean,
        default: () => false,
    })
    showScanDate!: boolean;

    @Prop({
        type: Date,
    })
    lastScan!: Date | undefined;

    get scanStatus() {
        if (!this.data) {
            return null;
        }

        return this.data.scanStatus;
    }

    get isScanning() {
        return this.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        return this.day ? !this.ratesCommonService.isScanAvailable(this.settings, this.day) : false;
    }

    async handleClick() {
        if (this.isScanning || this.disabled || !this.data) {
            return;
        }

        this.$emit('onTriggerScan', this.day);
    }

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    get formatScanDate() {
        const emptyDate = '--/--/--';

        if (this.lastScan) return formatDate(this.lastScan);

        if (!this.day || !this.data) {
            return emptyDate;
        }

        const doc = this.ratesCommonService.getUpdateDate(this.day, this.data);

        if (doc === null) {
            return emptyDate;
        }

        return formatDate(doc);
    }
}
