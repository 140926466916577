










import { Component, Vue, Prop } from 'vue-property-decorator';
import PercentFilter from '@/modules/common/filters/percent.filter';
import Occupancy from '@/modules/occupancy/components/occupancy.vue';

@Component({
    filters: { PercentFilter },
    components: { Occupancy },
})
export default class RatesPopupOccupancy extends Vue {
    @Prop({
        type: Number,
    })
    occupancy!: number | null;

    get value() {
        if (this.occupancy) {
            return (this.occupancy * 100).toFixed();
        }
        return null;
    }
}
